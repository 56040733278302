import { Component, OnInit } from '@angular/core';
import { ngxLoadingAnimationTypes, NgxLoadingConfig } from 'ngx-loading';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  loading: boolean = false;
  config: NgxLoadingConfig = {
    animationType: ngxLoadingAnimationTypes.cubeGrid,
    backdropBackgroundColour: 'rgba(0,0,0,0.05)', 
    backdropBorderRadius: '10px',
    primaryColour: '#743bbc', 
    secondaryColour: '#3b62a2', 
    tertiaryColour: '#333', 
    fullScreenBackdrop: true
  }

  constructor(
    private loadindService: LoadingService
  ) {
    this.loadindService.onLoading.subscribe(x => {
      this.loading = x;
    })
  }

  ngOnInit(): void {
  }

}
