import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'schedule-app';

  constructor(
    private location: Location
  ) {}
  ngOnInit(): void {
    var pathString = this.location.path()
    sessionStorage.setItem('app', pathString.includes("app") ? "app" : "client_key");
  }
}
