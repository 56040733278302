import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpApiService } from '../../../../shared/services/http-api.service';
import { ClinicAddress } from '../interfaces/clinic.model';
import { PageDataTotalTypes, PageDataScheduleTypes } from '../interfaces/page-data.enum';
import { ClinicAddressResponse } from '../interfaces/schedule-reponse.model';
import { PublicScheduleTypeEnum } from '../interfaces/type.model';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  private publicScheduleData: any;
  private pagesData: any;
  private clientKey!: any;

  private $publicScheduleData: BehaviorSubject<any> = new BehaviorSubject({});
  private $publicScheduleSuccessData: BehaviorSubject<any> = new BehaviorSubject(null);
  private $pagesData: BehaviorSubject<any> = new BehaviorSubject({});
  
  onPublicScheduleData = this.$publicScheduleData.asObservable();
  onPublicScheduleSuccessData = this.$publicScheduleSuccessData.asObservable();
  onPagesData = this.$pagesData.asObservable();
  
  constructor(
    private router: Router,
    private httpApiService: HttpApiService
  ) {
    this.onPublicScheduleData.subscribe(x => {
      this.publicScheduleData = x;
    });
    this.onPagesData.subscribe(x => {
      this.pagesData = x;
    });
    this.httpApiService.onClientKey.subscribe(clientKey => {
      this.clientKey = clientKey;
    });
  }

  private getPublicScheduleDataStorage(): void {
    const publicScheduleData = sessionStorage.getItem('publicScheduleData');
    if (publicScheduleData) {
      this.setPublicScheduleData(JSON.parse(publicScheduleData));
    }
  }

  private getPagesDataSessionStorage(): void {
    const pagesData = sessionStorage.getItem('pagesData');
    if (pagesData) {
      this.setPagesData(JSON.parse(pagesData));
    }
  }

  private setPublicScheduleData(data: any) {
    this.publicScheduleData = Object.assign(this.publicScheduleData, data);
    this.$publicScheduleData.next(this.publicScheduleData);
    sessionStorage.setItem('publicScheduleData', JSON.stringify(this.publicScheduleData));
  }

  private setPagesData(data: any) {
    this.pagesData = Object.assign(this.pagesData, data);
    this.$pagesData.next(this.pagesData);
    sessionStorage.setItem('pagesData', JSON.stringify(this.pagesData));
  }

  public getTypeDescriptionByName(name: string): string {
    if (name === PublicScheduleTypeEnum.DENTAL_APPOINTMENT) {
      return 'Consulta / Avaliação'
    }
    return name;
  }

  public getTypeIconByName(name: string): string {
    switch (name) {
      case PublicScheduleTypeEnum.DENTAL_APPOINTMENT:
        return 'tooth';
      case PublicScheduleTypeEnum.DENTAL_RETURN:
        return 'history';
      case PublicScheduleTypeEnum.PERIODIC:
        return 'user-clock';
      case PublicScheduleTypeEnum.PERSONAL_COMMITMENT:
      case PublicScheduleTypeEnum.EVALUATION:
        return 'calendar-plus';
      default:
        return 'calendar-plus';
    }
  }

  generateFormatedAddress(address: ClinicAddress | ClinicAddressResponse | undefined): any {
    if (address && address.street) {
      let addressFormated = '';
      if (address.street) {
        addressFormated = addressFormated.concat(address.street);
      }
      if (address.number) {
        addressFormated = addressFormated.concat(', ');
        addressFormated = addressFormated.concat(address.number);
      }
      if (address.neighborhood) {
        addressFormated = addressFormated.concat(', ');
        addressFormated = addressFormated.concat(address.neighborhood);
      }
      if (address.city && address.state) {
        addressFormated = addressFormated.concat('. ');
        addressFormated = addressFormated.concat(address.city);
        addressFormated = addressFormated.concat('/');
        addressFormated = addressFormated.concat(address.state);
      }
      if (address.zipcode) {
        addressFormated = addressFormated.concat(', ');
        addressFormated = addressFormated.concat(address.zipcode);
      }
      return addressFormated.toString();
    } else {
      return undefined;
    }
  }

  navigateToClinicsPage(): void {
    this.router.navigate([`${this.clientKey}/clinics`]);
  }

  setPublicScheduleSuccessData(data: any): void {
    this.$publicScheduleSuccessData.next(data);
  }

  getDatasStorage(): void {
    this.getPublicScheduleDataStorage();
    this.getPagesDataSessionStorage();
  }

  setPageDataTotal(type: PageDataTotalTypes, total: number) {
    let obj: any = {};
    obj[type] = total;
    this.setPagesData(obj);
  }

  getBackRouterLinkToClinic(): string | undefined {
    if (this.pagesData[PageDataTotalTypes.CLINICS_TOTAL] === 1) {
      return undefined;
    }
    return '../';
  }
  
  getBackRouterLinkToDentist(): string {
    if (this.pagesData[PageDataTotalTypes.DENTISTS_TOTAL] === 1) {
      return `../../`;
    }
    return `../`;
  }

  setPublicScheduleSelectedData(type: PageDataScheduleTypes, data: any): void {
    let obj: any = {};
    obj[type] = data;
    this.setPublicScheduleData(obj);
  }

  clearPublicScheduleData(): void {
    this.publicScheduleData = {};
    this.$publicScheduleData.next({});
    sessionStorage.setItem('publicScheduleData', '');
  }

  clearPageData(): void {
    this.pagesData = {};
    this.$pagesData.next({});
    sessionStorage.setItem('pagesData', '');
  }
}
