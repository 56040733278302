import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpApiService {
  private baseUrl = environment.baseUrl;

  private clientKey!: string;
  private $clientKey: BehaviorSubject<any> = new BehaviorSubject(null);

  onClientKey = this.$clientKey.asObservable();

  private api!: string;
  private $api: BehaviorSubject<any> = new BehaviorSubject(null);

  onApi = this.$api.asObservable();

  constructor() {
    if (sessionStorage.getItem('app') == 'app') {
      this.$clientKey.pipe(filter((x) => !!x)).subscribe((clientKey) => {
        this.$api.pipe(filter((x) => !!x)).subscribe((api) => {
          this.clientKey = clientKey;
          this.api = api;
          this.baseUrl = `${this.baseUrl}/${clientKey}`;
          if (api) this.baseUrl = `${this.baseUrl.replace('app', api)}`;
        });
      });
    } else {
      this.$clientKey.pipe(filter((x) => !!x)).subscribe((clientKey) => {
        this.clientKey = clientKey;
        this.baseUrl = `${this.baseUrl}/${clientKey}`;
        if (sessionStorage.getItem('api')) {
          var api = sessionStorage.getItem('api');
          if (api) this.baseUrl = `${this.baseUrl.replace('app', api)}`;
        } else {
          this.$api.pipe(filter((x) => !!x)).subscribe((api) => {
            if (api) this.baseUrl = `${this.baseUrl.replace('app', api)}`;
          });
        }
      });
    }
  }

  getBasePath(): string {
    return this.baseUrl;
  }

  setClientKey(clientKey: string): void {
    if (!this.clientKey) {
      this.$clientKey.next(clientKey);
    }
  }

  setApi(api: string): void {
    if (!this.api) {
      this.$api.next(api);
    }
  }

  setApiClinicKey(api: string): void {
    sessionStorage.setItem('api', api);
  }

  getClientKey(){
    return this.clientKey
  }
}
