import { HttpErrorResponse, HttpEvent, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

const ERROR_MESSAGES: any = [
  {
    title: 'Erro inesperado', 
    message: 'Ocorreu um erro inesperado'
  }
]

@Injectable({
  providedIn: 'root'
})
export class HttpExceptionService {

  private $onInvalidTimeError: BehaviorSubject<any> = new BehaviorSubject(null);
  onInvalidTimeError = this.$onInvalidTimeError.asObservable();

  constructor(
    private router: Router,
    private toastrService: ToastrService
  ) { }

  handleError(error: HttpEvent<any>): void {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 422:
          this.$onInvalidTimeError.next(error.error.errors);
          break;
        case 404:
          this.router.navigate(['/error']);
          break;
        default:
          this.toastrService.error(
            ERROR_MESSAGES[0].message,
            ERROR_MESSAGES[0].title);
          break;
      }
    } 
  }

  clearInvalidTimeError(): void {
    this.$onInvalidTimeError.next(null);
  }
}
