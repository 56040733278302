export enum PageDataTotalTypes {
    CLINICS_TOTAL = 'clinics_total',
    DENTISTS_TOTAL = 'dentists_total'
}

export enum PageDataScheduleTypes {
    CLIENT_KEY_DATA = 'client_key', 
    CLINIC_SCHEDULE_DATA = 'clinic',
    TYPE_SCHEDULE_DATA = 'schedule_type',
    DENTIST_SCHEDULE_DATA = 'dentist',
    AVAILABLE_HOURS_SCHEDULE_DATA = 'available_hour',
    CUSTOMER_SCHEDULE_DATA = 'customer'
}