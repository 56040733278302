import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { PublicService } from '../services/public.service';
import { PageDataScheduleTypes } from '../interfaces/page-data.enum';
import { HttpApiService } from '../../../../shared/services/http-api.service';

@Injectable()
export class PublicGuard implements CanActivate {
  constructor(
    private publicService: PublicService,
    private httpApiService: HttpApiService
  ) {
    this.publicService.getDatasStorage();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const params = route.params;
    if (params && params.clientKey) {
      if (params.api) this.httpApiService.setApi(params.api);
      this.httpApiService.setClientKey(params.clientKey);
      this.publicService.setPublicScheduleSelectedData(
        PageDataScheduleTypes.CLIENT_KEY_DATA,
        params.clientKey
      );
      return true;
    } else {
      return false;
    }
  }
}
