export interface PublicScheduleType {
    id: string;
    name: string
}

export enum PublicScheduleTypeEnum {
    DENTAL_APPOINTMENT = 'Consulta',
    DENTAL_RETURN = 'Retorno',
    PERIODIC = 'Periódico',
    PERSONAL_COMMITMENT = 'Compromisso pessoal',
    OTHER = 'Outro',
    EVALUATION = 'Avaliação'
}